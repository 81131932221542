import { gql } from '@/__generated__'

gql(`
  fragment gateTransactionMetadata on GateTransactionEventMetadata {
    laneId,
    transactionType
          
    appointmentType,
    powerUnitCarrierMCNum,
    powerUnitCarrierName,
    powerUnitCarrierUsdot,
    powerUnitEmissionType,
    powerUnitFuelType,
    powerUnitLicensePlateNumber,
    powerUnitLicensePlateState,
    powerUnitOwnerId,
    powerUnitType,
    powerUnitVin,
    powerUnitWeightClass,
    accountName,
          
    driverCompanyId,
    driverFirstName,
    driverLastName,
    driverLicenseNumber,
    driverLicenseState,
    driverPhoneNumber,
          
    cargoAssetAssetType,
    cargoAssetCarrierName,
    cargoAssetLicensePlateNumber,
    cargoAssetLicensePlateState,
    cargoAssetOwnerId,
    chassisId,
    inspectionCompleted,
    loadStatus,
    mismatch,
    sealMatchPW,
    sealNumbers,
    shipmentNumber,
  }
`)

gql(`
  fragment gateTransactionDetails on GateTransaction {
    id,
    correlationId,
    createdBy,
    createdDate,
    eventTime,
    organizationId,
    referenceId,
    siteId,
    transactionType,
    type,
    laneDisplayName,

    metadata {
        ...gateTransactionMetadata
    }
  }
`)

gql(`
  fragment paginationResponse on Pagination {
    totalItemsCount
    totalPages
    currentPage
  }
`)

export const GetGateTransactionsQuery = gql(`
  query ListGateTransactions($input: ListGateTransactionEventsInput!) {
    listGateTransactions(input: $input) {
      events {
        ...gateTransactionDetails
      }

      pagination {
        ...paginationResponse
      }
    }
  }
`)

export const GetEnterpriseGateTransactionsQuery = gql(`
  query ListEnterpriseGateTransactions($input: ListEnterpriseGateTransactionEventsInput!) {
    listEnterpriseGateTransactions(input: $input) {
      events {
        ...gateTransactionDetails
      }

      pagination {
        ...paginationResponse
      }
    }
  }
`)

export const GetCargoAssetsOnsiteQuery = gql(`
  query ListCargoAssetsOnsite($input: ListGateTransactionEventsInput!) {
    listCargoAssetsOnSite(input: $input) {
      assets {
        ...gateTransactionDetails
      }

      pagination {
        ...paginationResponse
      }
    }
  }
`)

export const GetEnterpriseCargoAssetsOnsiteQuery = gql(`
  query ListEnterpriseCargoAssetsOnsite($input: ListEnterpriseGateTransactionEventsInput!) {
    listEnterpriseCargoAssetsOnSites(input: $input) {
      assets {
        ...gateTransactionDetails
      }

      pagination {
        ...paginationResponse
      }
    }
  }
`)

export const GetDriversOnsiteQuery = gql(`
  query ListDriversOnsite($input: ListGateTransactionEventsInput!) {
    listDriversOnSite(input: $input) {
      drivers {
        ...gateTransactionDetails
      }

      pagination {
        ...paginationResponse
      }
    }
  }
`)

export const GetEnterpriseDriversOnsiteQuery = gql(`
  query ListEnterpriseDriversOnsite($input: ListEnterpriseGateTransactionEventsInput!) {
    listEnterpriseDriversOnSites(input: $input) {
      drivers {
        ...gateTransactionDetails
      }

      pagination {
        ...paginationResponse
      }
    }
  }
`)
