import { IHighOrderColumn } from '@/types/interfaces/table'

const gateTransactionsHighOrderColumns = (
  isEnterpriseMode?: boolean
): IHighOrderColumn[] => [
  {
    id: 'general',
    title: 'General',
    // [Transaction Time | Direction | Event Type | Visitor | Site (only in enterprise mode)]
    colSpan: isEnterpriseMode ? 5 : 4
  },
  {
    id: 'power-unit',
    title: 'Power Unit',
    // [ID | LPN | State | USDOT | MC # | VIN | Class | Fuel Type | Emission | Carrier | Account]
    colSpan: 11
  },
  {
    id: 'driver',
    title: 'Driver Details',
    // [Name | License | Phone #]
    colSpan: 3
  },
  {
    id: 'cargo-asset',
    title: 'Cargo Asset',
    // [ID | LPN | State | Carrier | Type | Load Status | Shipment # | Seal # | Seal Matches | Inspection | Source | Mismatch]
    colSpan: 12
  }
]

export default gateTransactionsHighOrderColumns
