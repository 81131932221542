import { useCallback, useEffect, useState } from 'react'

import { Button } from '@/components/atoms'
import { Tabs } from '@/components/molecules'
import { Table } from '@/components/organisms'
import { FullSpaceTemplate, WithHeaderTemplate } from '@/components/templates'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { useSnackbar } from '@/features/snackbars-queue'
import {
  AddEditUserModal,
  CancelInviteModal,
  DeleteUserModal,
  DisableUserModal
} from '@/features/user-management'
import {
  useCancelInviteMutation,
  useDeleteUserMutation,
  useEnableDisableUserMutation,
  useFetchInvitesQuery,
  useFetchUsersQuery
} from '@/features/user-management/api'
import {
  UserManagementMessageType,
  UserManagementModalType
} from '@/features/user-management/enums'
import { IUser, UserManagementTab } from '@/features/user-management/types'
import {
  invitesColumns,
  userManagementColumns
} from '@/features/user-management/utils'
import { useLastUrlPart, useModal } from '@/hooks'
import { useStore } from '@/store'
import { TableActions } from '@/types/enums/table'
import { ITab } from '@/types/interfaces/ui'
import DriversOnSite from '@/assets/icons/drivers_on_site.svg?react'
import { TableRequestDetails } from '@/types/interfaces/table'
import { prepareSortForRestApi } from '@/api'
import { useNavigator } from '@/router'

const UserManagementTemplate = () => {
  const RELOAD_WINDOW_DELAY = 500

  const { org, me } = useStore((store) => store.user)
  const { showDefaultSnackbar } = useSnackbar()

  const { organization_id = '' } = org || {}
  const { user_id = '' } = me || {}

  const activeTab = useLastUrlPart() as UserManagementTab

  const navigator = useNavigator()

  const setTab = useCallback(
    (tab: UserManagementTab) => {
      if (tab === UserManagementTab.Users) navigator.toEnterpriseUsers()
      else navigator.toEnterpriseInvites()
    },
    [navigator]
  )

  useEffect(() => {
    const currentTabValid = Object.values(UserManagementTab).includes(activeTab)
    if (currentTabValid) setTab(activeTab)
    else setTab(UserManagementTab.Users)
  }, [activeTab])

  const [selectedItem, setSelectedItem] = useState<IUser | undefined>()

  const [{ search, sortBy, currentPage }, setRequestDetails] =
    useState<TableRequestDetails>({
      currentPage: 1,
      search: '',
      sortBy: undefined
    })

  const { data: usersResponse, isFetching: usersFetching } = useFetchUsersQuery(
    {
      orgId: organization_id,
      search_query: search,
      current_page: currentPage,
      items_per_page: PAGINATION_ITEMS_PER_PAGE,
      sort: prepareSortForRestApi(sortBy)
    }
  )

  const { data: invitesResponse, isFetching: invitesFetching } =
    useFetchInvitesQuery({ orgId: organization_id })

  const [enableDisableUser, { isLoading: enableDisableLoading }] =
    useEnableDisableUserMutation()

  const [cancelInvite, { isLoading: cancelLoading }] = useCancelInviteMutation()
  const [deleteUser, { isLoading: deleteLoading }] = useDeleteUserMutation()

  const commonModal = useModal<UserManagementModalType>(undefined, () =>
    setSelectedItem(undefined)
  )
  const disableModal = useModal<boolean>(false, () =>
    setSelectedItem(undefined)
  )

  const closeModal = (type: 'common' | 'disable' | '') => {
    if (type === 'common') {
      commonModal.closeModal()
    } else {
      disableModal.closeModal()
    }

    setSelectedItem(undefined)
  }

  const onDelete = async () => {
    if (!selectedItem || !organization_id) return

    const response = await deleteUser({
      orgId: organization_id,
      userId: selectedItem.id
    })

    if (response?.data?.data?.success) {
      closeModal('common')
      showDefaultSnackbar(UserManagementMessageType.UserDeleted)
    }
  }

  const onCancel = async () => {
    if (!selectedItem || !organization_id) return

    const response = await cancelInvite({
      orgId: organization_id,
      inviteId: selectedItem.id
    })

    if (response?.data?.data?.success) {
      closeModal('common')
      showDefaultSnackbar(UserManagementMessageType.InvitationCanceled)
    }
  }

  const onDisable = async () => {
    if (!selectedItem?.id || !organization_id) return

    const response = await enableDisableUser({
      disable: true,
      orgId: organization_id,
      userId: selectedItem.id
    })

    if (!response?.data?.errors) {
      closeModal('disable')
      showDefaultSnackbar(UserManagementMessageType.AccessDisabled)
    }
  }

  const onEnable = async (userId: string) => {
    if (!organization_id) return

    const response = await enableDisableUser({
      disable: false,
      orgId: organization_id,
      userId
    })

    if (!response?.data?.errors) {
      showDefaultSnackbar(UserManagementMessageType.AccessEnabled)
    }
  }

  const handleAction = (action: TableActions, item: IUser) => {
    switch (action) {
      case TableActions.Delete: {
        commonModal.openModal(UserManagementModalType.Delete)
        setSelectedItem(item)
        break
      }

      case TableActions.Cancel: {
        commonModal.openModal(UserManagementModalType.CancelInvite)
        setSelectedItem(item)
        break
      }

      case TableActions.Edit:
      case TableActions.RowClick: {
        commonModal.openModal(UserManagementModalType.AddEditUser)
        setSelectedItem(item)
        break
      }

      case TableActions.Toggle: {
        if (item.disabled) {
          onEnable(item.id)
        } else {
          disableModal.openModal()
          setSelectedItem(item)
        }

        break
      }

      default: {
        break
      }
    }
  }

  const onInviteCallback = () => {
    closeModal('common')
    setTab(UserManagementTab.Invites)

    showDefaultSnackbar(UserManagementMessageType.UserInvited)
  }

  const onEditCallback = () => {
    const editedUserId = selectedItem?.id

    closeModal('common')
    showDefaultSnackbar(UserManagementMessageType.UserUpdated)

    if (editedUserId === user_id) {
      setTimeout(() => {
        window.location.reload()
      }, RELOAD_WINDOW_DELAY)
    }
  }

  const isRowHighlighted = (row: IUser) => row.disabled
  const pagination = usersResponse?.pagination

  useEffect(() => {
    // If user was activated/deactivated from edit popup we need
    // to pass updated data in form
    if (usersResponse?.data?.members && selectedItem) {
      setSelectedItem(
        usersResponse.data.members.find((user) => user.id === selectedItem.id)
      )
    }
  }, [usersResponse?.data?.members])

  const tabs: ITab<UserManagementTab>[] = [
    {
      id: UserManagementTab.Users,
      title: 'Users',
      items: usersResponse?.pagination?.total_items_count || 0,
      Component: (
        <Table
          clickable
          withSearch
          key="users-table"
          name="users"
          loading={usersFetching}
          rows={usersResponse?.data?.members || []}
          columns={userManagementColumns}
          handleAction={handleAction}
          isRowHighlighted={isRowHighlighted}
          searchPlaceholder="Search name or email"
          perPage={PAGINATION_ITEMS_PER_PAGE}
          currentPage={currentPage}
          totals={{
            pages: pagination?.total_pages,
            items: pagination?.total_items_count
          }}
          onUpdateRequestDetails={setRequestDetails}
          noResultsMessage={{
            primaryText: 'No Users',
            descriptionText:
              'No users found. When a user accepts an invite they will appear here.',
            Icon: <DriversOnSite />
          }}
        />
      )
    },
    {
      id: UserManagementTab.Invites,
      title: 'Pending Invites',
      items: invitesResponse?.data?.invites?.length || 0,
      Component: (
        <Table
          key="invites-table"
          name="invites"
          loading={invitesFetching}
          searchPlaceholder="Search"
          columns={invitesColumns}
          handleAction={handleAction}
          rows={invitesResponse?.data?.invites || []}
          noResultsMessage={{
            primaryText: 'No pending invites',
            descriptionText:
              "When you invite a user, they'll appear here until they accept your invite. You can also cancel pending invites",
            Icon: <DriversOnSite />,
            buttonText: 'Invite User',
            buttonAction: () =>
              commonModal.openModal(UserManagementModalType.AddEditUser)
          }}
        />
      )
    }
  ]

  return (
    <WithHeaderTemplate
      title="User Management"
      showBottomBorder={false}
      Action={
        <Button
          type="primary"
          onClick={() =>
            commonModal.openModal(UserManagementModalType.AddEditUser)
          }
        >
          Invite User
        </Button>
      }
    >
      {commonModal.visible === UserManagementModalType.Delete && (
        <DeleteUserModal
          onDelete={onDelete}
          closeModal={() => closeModal('common')}
          name={selectedItem?.name as string}
          loading={deleteLoading}
        />
      )}

      {commonModal.visible === UserManagementModalType.CancelInvite && (
        <CancelInviteModal
          onCancel={onCancel}
          closeModal={() => closeModal('common')}
          loading={cancelLoading}
          email={selectedItem?.email as string}
        />
      )}

      {commonModal.visible === UserManagementModalType.AddEditUser && (
        <AddEditUserModal
          selectedItem={selectedItem}
          editMode={!!selectedItem}
          closeModal={() => closeModal('common')}
          onEditCallback={onEditCallback}
          onInviteCallback={onInviteCallback}
          toggleActivateStatus={() =>
            handleAction(TableActions.Toggle, selectedItem as IUser)
          }
        />
      )}

      {disableModal.visible && (
        <DisableUserModal
          fullName={(selectedItem?.name || selectedItem?.email) as string}
          firstName={selectedItem?.first_name as string}
          onDisable={onDisable}
          closeModal={() => closeModal('disable')}
          loading={enableDisableLoading}
        />
      )}

      <FullSpaceTemplate>
        <Tabs<UserManagementTab>
          active={activeTab}
          setActive={(tab) => setTab(tab || UserManagementTab.Users)}
          tabs={tabs}
        />
      </FullSpaceTemplate>
    </WithHeaderTemplate>
  )
}

export default UserManagementTemplate
