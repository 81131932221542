import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'

import { useState } from 'react'
import clsx from 'clsx'

import { Button, Col, Row, Text } from '@/components/atoms'
import { WithHeaderTemplate } from '@/components/templates'
import { ROUTES } from '@/router'
import { useStore } from '@/store'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import { Modal } from '@/components/organisms'
import { Launch } from '@mui/icons-material'

import useSnackbar from '@/features/snackbars-queue/hooks/useSnackbar'
import { useEnableSsoMutation } from '@/features/user-management/api'
import { EnableSsoTile, EnterpriseLandingCard } from '@/features/home/component'

import { useSsoEnabled } from '../../hooks/useSsoEnabled'
import styles from './EnterpriseLanding.module.scss'

const ADMIN_TOOLS = [
  {
    title: 'Users',
    link: ROUTES.ENTERPRISE_USERS,
    icon: <GroupOutlinedIcon />
  }
]

const DATA_ACROSS_SITES = [
  {
    title: 'Gate Transactions',
    link: ROUTES.ENTERPRISE_GATE_TRANSACTIONS,
    icon: <LocalShippingOutlinedIcon />
  },
  {
    title: 'Drivers On Site',
    link: ROUTES.ENTERPRISE_DRIVERS,
    icon: <DirectionsCarOutlinedIcon />
  },
  {
    title: 'Cargo Assets On Site',
    link: ROUTES.ENTERPRISE_CARGO_ASSETS,
    icon: <InventoryOutlinedIcon />
  }
]

const EnterpriseLanding = () => {
  const { showErrorSnackbar } = useSnackbar()
  const { me, org } = useStore((store) => store.user)

  const { first_name = '' } = me || {}

  const [enableSso, { isLoading: generatingTicket }] = useEnableSsoMutation()
  const [enableSsoModalOpen, setEnableSsoModalOpen] = useState<boolean>(false)

  const { ssoEnabled } = useSsoEnabled()

  const onEnableSsoClicked = async () => {
    if (!org) return
    const orgId = org.organization_id

    const response = await enableSso({
      orgId,
      connection_name: 'okta-sso'
    })

    const ticketLink = response?.data?.data?.ticket || ''

    if (!ticketLink) {
      showErrorSnackbar('Failed to generate setup link')
      return
    }

    window.open(ticketLink, '_blank')
    setEnableSsoModalOpen(false)
  }

  return (
    <WithHeaderTemplate
      title={`Hi ${first_name}!`}
      className={styles.landingPageWrapper}
    >
      {enableSsoModalOpen && (
        <Modal
          title={'Enable SSO'}
          closeModal={() => setEnableSsoModalOpen(false)}
          cancelButtonText="Cancel"
          footer={
            <Row items="stretch" gap={8}>
              <Button
                onClick={onEnableSsoClicked}
                type="primary"
                endIcon={<Launch />}
                disabled={generatingTicket}
              >
                {generatingTicket ? 'Generating...' : 'Continue'}
              </Button>
            </Row>
          }
        >
          <Col gap={8}>
            <Text type={TextTypes.TEXT_SM} color={Color.gray700}>
              Continue to generate a custom link that will redirect you to
              complete your SSO complete setup.
            </Text>
            <Text type={TextTypes.TEXT_SM} color={Color.gray700}>
              This link will expire in <b>5 hours</b>. If the setup is not
              finished within that time, you will need to return here to restart
              the process.
            </Text>
          </Col>
        </Modal>
      )}

      <Col gap={40} className={styles.landingPageContent}>
        <Text type={TextTypes.DISPLAY_XS} color={Color.gray700}>
          Where would you like to go?
        </Text>

        <Col gap={16}>
          <Text
            type={TextTypes.TEXT_SM}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray600}
          >
            Admin Tools
          </Text>

          <section className={styles.listItems}>
            {ADMIN_TOOLS.map((item) => (
              <EnterpriseLandingCard
                key={item.link}
                title={item.title}
                icon={item.icon}
                link={item.link}
              />
            ))}

            <EnableSsoTile
              ssoEnabledState={ssoEnabled}
              openEnableSsoModal={() => setEnableSsoModalOpen(true)}
            />
          </section>
        </Col>

        <Col gap={16}>
          <Text
            type={TextTypes.TEXT_SM}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray600}
          >
            Data Across Sites
          </Text>

          <section className={clsx(styles.listItems, styles.tablesLinks)}>
            {DATA_ACROSS_SITES.map((item) => (
              <EnterpriseLandingCard
                small
                key={item.link}
                title={item.title}
                icon={item.icon}
                link={item.link}
              />
            ))}
          </section>
        </Col>
      </Col>
    </WithHeaderTemplate>
  )
}

export default EnterpriseLanding
