export const ENTERPRISE_ROUTE_PREFIX = '/enterprise'

const ROUTES = {
  // Common routes
  HOME: '/',
  PROFILE: '/profile',

  // Site portal routes
  GATE: '/:site_id/:gate_id/gate',
  GATE_QUEUE: '/:site_id/:gate_id/gate/queue',
  GATE_TRANSACTIONS: '/:site_id/:gate_id/gate/transactions',
  GATE_DRIVERS: '/:site_id/:gate_id/yard/gate-drivers',
  YARD_CARGO_ASSET: '/:site_id/:gate_id/yard/cargo-asset',
  ISR: '/:site_id/:gate_id/isr',
  ADMIN_MISMATCHES: '/:site_id/:gate_id/administration/mismatches',
  ADMIN_VIDEO: '/:site_id/:gate_id/administration/video',

  // Enterprise portal routes
  ENTERPRISE_USERS: `${ENTERPRISE_ROUTE_PREFIX}/users`,
  ENTERPRISE_INVITES: `${ENTERPRISE_ROUTE_PREFIX}/invites`,
  ENTERPRISE_GATE_TRANSACTIONS: `${ENTERPRISE_ROUTE_PREFIX}/gate/transactions`,
  ENTERPRISE_DRIVERS: `${ENTERPRISE_ROUTE_PREFIX}/yard/gate-drivers`,
  ENTERPRISE_CARGO_ASSETS: `${ENTERPRISE_ROUTE_PREFIX}/yard/cargo-asset`
}

export default ROUTES
