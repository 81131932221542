import KeyIcon from '@/assets/icons/key.svg?react'
import OctaSvg from '@/assets/icons/octo.svg?react'
import { FC } from 'react'
import clsx from 'clsx'

import { EnterpriseLandingCard } from '@/features/home/component'

import { SsoEnabledState } from '../../types/sso'
import styles from './EnableSsoTile.module.scss'

interface Props {
  ssoEnabledState: SsoEnabledState
  openEnableSsoModal: () => void
}

const getCardContent = (state: SsoEnabledState) => {
  if (state === SsoEnabledState.Enabled) {
    return {
      title: 'Okta SSO',
      subTitle: 'Enabled',
      icon: <OctaSvg className={styles.blackLogo} />
    }
  }

  if (state === SsoEnabledState.NotEnabled) {
    return {
      title: 'Enable SSO',
      icon: <KeyIcon />
    }
  }

  return {
    subTitle: 'Loading...',
    icon: <KeyIcon />
  }
}

const EnableSsoTile: FC<Props> = (props) => {
  const { ssoEnabledState, openEnableSsoModal } = props

  const disabled = ssoEnabledState !== SsoEnabledState.NotEnabled

  const { title, subTitle, icon } = getCardContent(ssoEnabledState)

  return (
    <EnterpriseLandingCard
      title={title}
      subTitle={subTitle}
      icon={icon}
      disabled={disabled}
      onClick={() => {
        if (disabled) return
        openEnableSsoModal()
      }}
    />
  )
}

export default EnableSsoTile
