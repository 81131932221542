import { useQuery } from '@apollo/client'
import { FC, useCallback, useMemo, useState } from 'react'

import {
  ListGateTransactionEventsInput,
  OrderByDomainEventColumn,
  SearchDomainEventColumns,
  StringOperator
} from '@/__generated__/graphql'
import {
  GetEnterpriseGateTransactionsQuery,
  GetGateTransactionsQuery
} from '@/features/gate/api/queries'

import GateTransactions from '@/assets/icons/gate_transactions.svg?react'
import { Table } from '@/components/organisms'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { useFetchAccountsQuery } from '@/features/gate/api'
import {
  transactionsColumns,
  transactionsHighOrderColumns
} from '@/features/gate/utils'
import { useStore } from '@/store'

import { prepareSortForGraphQlApi } from '@/api'
import { PortalTypes } from '@/types/enums/global'
import { WithHeaderTemplate } from '@/components/templates'
import {
  DEFAULT_TABLE_REQUEST_DETAILS,
  TableRequestDetails
} from '@/types/interfaces/table'
import {
  accountFilter,
  appointmentTypeFilter,
  dateRangeFilter,
  emissionTypeFilter,
  eventTypeFilter,
  fuelTypeFilter,
  loadStatusFilter,
  mismatchFilter,
  siteFilter,
  transactionDirectionFilter,
  weightClassFilter
} from '@/utils/table'

import styles from './GateTransactionTemplate.module.scss'

interface Props {
  enterpriseMode?: boolean
}

const TABLE_ID = 'gate-transactions'
const ENTERPRISE_TABLE_ID = 'enterprise-gate-transactions'

const GateTransactionTemplate: FC<Props> = (props) => {
  const { enterpriseMode = false } = props
  const { selectedPortal, org, portals } = useStore((store) => store.user)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [
    { currentPage, search, sortBy, filters, dateRange },
    setRequestDetails
  ] = useState<TableRequestDetails>(DEFAULT_TABLE_REQUEST_DETAILS)

  const { siteIDs, ...restFilters } = filters || {}

  const { data: accounts = [] } = useFetchAccountsQuery(
    {
      site_id,
      org_id
    },
    { skip: !site_id || enterpriseMode }
  )

  const baseQueryInput: Omit<ListGateTransactionEventsInput, 'siteID'> = {
    organizationID: org_id,
    currentPage,
    itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    search: {
      fields: [
        SearchDomainEventColumns.PowerUnitOwnerId,
        SearchDomainEventColumns.PowerUnitLicensePlateNumber,
        SearchDomainEventColumns.PowerUnitCarrierUsdot,
        SearchDomainEventColumns.PowerUnitCarrierMcnum,
        SearchDomainEventColumns.PowerUnitVin,
        SearchDomainEventColumns.PowerUnitCarrierName,
        SearchDomainEventColumns.AccountName,
        SearchDomainEventColumns.DriverName,
        SearchDomainEventColumns.DriverLicenseNumber,
        SearchDomainEventColumns.DriverPhoneNumber,
        SearchDomainEventColumns.CargoAssetOwnerId,
        SearchDomainEventColumns.CargoAssetLicensePlateNumber,
        SearchDomainEventColumns.CargoAssetCarrierName,
        SearchDomainEventColumns.ShipmentNumber,
        SearchDomainEventColumns.SealNumbers
      ],
      operator: StringOperator.Contains,
      value: search
    },
    order: prepareSortForGraphQlApi<OrderByDomainEventColumn>(sortBy),
    filter: restFilters || undefined,
    dateRange
  }

  const { loading, data } = useQuery(GetGateTransactionsQuery, {
    variables: {
      input: {
        siteID: site_id,
        ...baseQueryInput
      }
    },
    skip: enterpriseMode
  })

  const { loading: enterpriseLoading, data: enterpriseData } = useQuery(
    GetEnterpriseGateTransactionsQuery,
    {
      variables: {
        input: {
          siteIDs: siteIDs?.values,
          ...baseQueryInput
        }
      },
      skip: !enterpriseMode
    }
  )

  const transactions = enterpriseMode
    ? enterpriseData?.listEnterpriseGateTransactions?.events || []
    : data?.listGateTransactions?.events || []

  const pagination = enterpriseMode
    ? enterpriseData?.listEnterpriseGateTransactions?.pagination
    : data?.listGateTransactions?.pagination

  const sites = useMemo(
    () => portals.filter((portal) => portal.type === PortalTypes.Site),
    [portals]
  )

  const filtersList = useMemo(
    () => [
      dateRangeFilter,
      ...(enterpriseMode ? [siteFilter(sites)] : []),
      transactionDirectionFilter,
      eventTypeFilter,
      appointmentTypeFilter,
      weightClassFilter,
      fuelTypeFilter,
      emissionTypeFilter,
      loadStatusFilter,
      ...(!enterpriseMode ? [accountFilter(accounts)] : []),
      mismatchFilter
    ],
    [accounts, sites]
  )

  const columns = useCallback(
    () => transactionsColumns(enterpriseMode, sites),
    [sites]
  )

  const highOrderColumns = useCallback(
    () => transactionsHighOrderColumns(enterpriseMode),
    [enterpriseMode]
  )

  const GateTransactionsTable = (
    <div className={styles.gateTransactionTemplate}>
      <div className="tw-flex-1 tw-flex">
        <Table
          withSearch
          name={enterpriseMode ? ENTERPRISE_TABLE_ID : TABLE_ID}
          loading={loading || enterpriseLoading}
          rows={transactions}
          filters={filtersList}
          columns={columns}
          highOrderColumns={highOrderColumns}
          searchPlaceholder="Search Transaction"
          perPage={PAGINATION_ITEMS_PER_PAGE}
          currentPage={currentPage}
          totals={{
            items: pagination?.totalItemsCount,
            pages: pagination?.totalPages
          }}
          onUpdateRequestDetails={setRequestDetails}
          noResultsMessage={{
            primaryText: 'No gate transactions',
            descriptionText:
              'Gate Check-In and Check-Out transactions will appear here.',
            Icon: <GateTransactions />
          }}
        />
      </div>
    </div>
  )

  return enterpriseMode ? (
    <WithHeaderTemplate title="Gate Transactions">
      {GateTransactionsTable}
    </WithHeaderTemplate>
  ) : (
    GateTransactionsTable
  )
}

export default GateTransactionTemplate
