import { FC, useCallback, useMemo, useState } from 'react'

import CargoAssetOnSite from '@/assets/icons/cargo_asset_on_site.svg?react'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { useFetchAccountsQuery } from '@/features/gate/api'
import { EditCargoAssetOnsiteModal } from '@/features/yard'
import { cargoAssetsTableShape } from '@/features/yard/utils'
import { useModal } from '@/hooks'
import { useStore } from '@/store'
import { TableActions } from '@/types/enums/table'

import {
  GateTransaction,
  ListGateTransactionEventsInput,
  OrderByDomainEventColumn,
  SearchDomainEventColumns,
  StringOperator
} from '@/__generated__/graphql'
import { prepareSortForGraphQlApi } from '@/api'
import {
  GetCargoAssetsOnsiteQuery,
  GetEnterpriseCargoAssetsOnsiteQuery
} from '@/features/gate/api/queries'
import {
  DEFAULT_TABLE_REQUEST_DETAILS,
  TableRequestDetails
} from '@/types/interfaces/table'
import { useQuery } from '@apollo/client'
import { useCheckMyPermissionsQuery } from '@/features/auth/api'
import { LaneRelation, PermissionResourceType } from '@/features/auth/enums'
import { preparePermissionResourceQuery } from '@/features/auth/helpers'
import {
  accountFilter,
  appointmentTypeFilter,
  dateRangeFilter,
  dwellFilter,
  loadStatusFilter,
  mismatchFilter,
  siteFilter
} from '@/utils/table'
import { PortalTypes } from '@/types/enums/global'

interface Props {
  enterpriseMode?: boolean
}

const TABLE_ID = 'cargo-assets'
const ENTERPRISE_TABLE_ID = 'enterprise-cargo-assets'

const CargoAssetTemplate: FC<Props> = (props) => {
  const { enterpriseMode = false } = props

  const [selectedItem, setSelectedItem] = useState<
    GateTransaction | undefined
  >()

  const { visible, openModal, closeModal } = useModal(false)
  const { selectedPortal, org, portals } = useStore((store) => store.user)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [
    { currentPage, search, sortBy, filters, dateRange },
    setRequestDetails
  ] = useState<TableRequestDetails>(DEFAULT_TABLE_REQUEST_DETAILS)
  const { siteIDs, ...restFilters } = filters || {}

  const baseInputQuery: Omit<ListGateTransactionEventsInput, 'siteID'> = {
    organizationID: org_id,
    currentPage,
    itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    search: {
      fields: [
        SearchDomainEventColumns.CargoAssetOwnerId,
        SearchDomainEventColumns.ChassisId,
        SearchDomainEventColumns.CargoAssetLicensePlateNumber,
        SearchDomainEventColumns.CargoAssetCarrierName,
        SearchDomainEventColumns.PowerUnitCarrierName,
        SearchDomainEventColumns.ShipmentNumber,
        SearchDomainEventColumns.SealNumbers,
        SearchDomainEventColumns.AccountName
      ],
      operator: StringOperator.Contains,
      value: search
    },
    order: prepareSortForGraphQlApi<OrderByDomainEventColumn>(sortBy),
    filter: restFilters,
    dateRange
  }

  const { loading, data, refetch } = useQuery(GetCargoAssetsOnsiteQuery, {
    variables: {
      input: {
        siteID: site_id,
        ...baseInputQuery
      }
    },
    skip: enterpriseMode
  })

  const { loading: enterpriseLoading, data: enterpriseData } = useQuery(
    GetEnterpriseCargoAssetsOnsiteQuery,
    {
      variables: {
        input: {
          siteIDs: siteIDs?.values,
          ...baseInputQuery
        }
      },
      skip: !enterpriseMode
    }
  )

  const { data: accounts = [] } = useFetchAccountsQuery(
    {
      site_id,
      org_id
    },
    { skip: !site_id || enterpriseMode }
  )

  const assets = enterpriseMode
    ? enterpriseData?.listEnterpriseCargoAssetsOnSites?.assets || []
    : data?.listCargoAssetsOnSite?.assets || []

  const pagination = enterpriseMode
    ? enterpriseData?.listEnterpriseCargoAssetsOnSites?.pagination
    : data?.listCargoAssetsOnSite?.pagination

  const laneId = assets?.[0]?.metadata?.laneId

  const { currentData: updatePermission, isFetching } =
    useCheckMyPermissionsQuery(
      {
        orgId: org_id,
        relation: LaneRelation.CreateLaneEvent,
        resource: preparePermissionResourceQuery(
          PermissionResourceType.Lane,
          laneId
        )
      },
      { skip: !laneId || enterpriseMode }
    )

  const isUpdateAllowed =
    !enterpriseMode && updatePermission?.data?.has_permission

  const sites = useMemo(
    () => portals.filter((portal) => portal.type === PortalTypes.Site),
    [portals]
  )

  const filtersList = useMemo(
    () => [
      dateRangeFilter,
      ...(enterpriseMode ? [siteFilter(sites)] : []),
      appointmentTypeFilter,
      ...(!enterpriseMode ? [accountFilter(accounts)] : []),
      loadStatusFilter,
      dwellFilter,
      mismatchFilter
    ],
    [accounts, sites]
  )

  const columns = useCallback(
    () => cargoAssetsTableShape(enterpriseMode, sites),
    [sites, enterpriseMode]
  )

  const handleAction = (action: TableActions, row: GateTransaction) => {
    switch (action) {
      case TableActions.RowClick: {
        if (!isUpdateAllowed) return

        setSelectedItem(row)
        openModal()
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <>
      {visible && (
        <EditCargoAssetOnsiteModal
          item={selectedItem!}
          closeModal={closeModal}
          refetch={refetch}
        />
      )}

      <WithHeaderTemplate title="Cargo Assets On Site">
        <div className="tw-flex-1 tw-flex">
          <Table
            withSearch
            name={enterpriseMode ? ENTERPRISE_TABLE_ID : TABLE_ID}
            rows={assets}
            clickable={isUpdateAllowed}
            loading={loading || enterpriseLoading || isFetching}
            handleAction={handleAction}
            filters={filtersList}
            columns={columns}
            searchPlaceholder="Search Cargo Asset"
            perPage={PAGINATION_ITEMS_PER_PAGE}
            totals={{
              items: pagination?.totalItemsCount,
              pages: pagination?.totalPages
            }}
            currentPage={currentPage}
            onUpdateRequestDetails={setRequestDetails}
            noResultsMessage={{
              primaryText: 'No cargo assets on site',
              descriptionText:
                'Cargo assets that have Checked-In but have not Checked-Out will appear here.',
              Icon: <CargoAssetOnSite />
            }}
          />
        </div>
      </WithHeaderTemplate>
    </>
  )
}

export default CargoAssetTemplate
