import { FC, JSX } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { Col, Text } from '@/components/atoms'
import { FontWeight, TextAlign, TextTypes } from '@/types/enums/ui'
import { Color } from '@/styles/palette'

import styles from './EnterpriseLandingCard.module.scss'

interface Props {
  small?: boolean
  disabled?: boolean
  onClick?: () => void

  title?: string
  subTitle?: string
  link?: string
  icon?: JSX.Element
}

const EnterpriseLandingCard: FC<Props> = (props) => {
  const { title, small, icon, subTitle, link, disabled, onClick } = props

  const Card = (
    <Col
      gap={24}
      items="center"
      className={clsx(styles.card, {
        [styles.small]: small,
        [styles.disabled]: disabled
      })}
    >
      {icon}

      <Col gap={8}>
        {title && (
          <Text
            type={TextTypes.TEXT_SM}
            weight={FontWeight.SEMIBOLD}
            align={TextAlign.CENTER}
            color={Color.gray700}
            className={styles.cardTitle}
          >
            {title}
          </Text>
        )}

        {subTitle && (
          <Text
            type={TextTypes.TEXT_XS}
            weight={FontWeight.SEMIBOLD}
            align={TextAlign.CENTER}
            color={Color.green500}
          >
            {subTitle}
          </Text>
        )}
      </Col>
    </Col>
  )

  return link ? (
    <Link
      to={link}
      className={clsx(styles.adminToolLink, { [styles.disabled]: disabled })}
    >
      {Card}
    </Link>
  ) : (
    <div onClick={onClick}>{Card}</div>
  )
}

export default EnterpriseLandingCard
